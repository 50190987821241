<template>
  <div class="component-intro">
    <div class="container">
      <div class="subject">서비스 소개</div>
      <div class="service">
        <div class="row">
          <div class="col-lg-6">
            <div class="logo">
              <span class="img" alt="프로필 사진" style="background-image: url(/assets/img/img.logo.point.svg)"></span>
              <span class="txt">frontree</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="text">
              <div class="header">
                <span class="font-ubuntu"> <b>front</b><span>end</span></span>
                <span> + </span>
                <b class="font-ubuntu">tree</b>
              </div>
              <div class="body">
                <div>프론트리는 프론트엔드(frontend)와 나무(tree)의 합성어로, 프론트엔드 개발자가 입문 과정인 씨앗 단계에서부터 튼튼한 나무로 성장하기까지의 과정을 지원하는 서비스입니다. 라이브 레슨과 동영상 강의로 개발자의 성장을 도와드립니다.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subject mt-5">강사 소개</div>
      <div class="teacher row">
        <div class="col-md-6 me">
          <div class="wrapper">
            <div class="profile">
              <div class="pic" :class="{ active: state.active.pic }">
                <span class="img" alt="프로필 사진" style="background-image: url(/assets/img/img.man.svg)"></span>
                <div class="message" :style="{ opacity: state.active.message ? 1 : 0 }">
                  <span>
                    <i class="font-ubuntu">Hello</i>
                    <span>😃</span>
                  </span>
                </div>
              </div>
              <div class="desc">
                <span class="mr-2">개발자 최진입니다</span>
                <span>👨‍💻</span>
              </div>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <th>이름</th>
                  <td>
                    <div>
                      <span>최진</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>나이</th>
                  <td>
                    <div>
                      <span>34세</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>경력</th>
                  <td>
                    <div>
                      <span>9년차</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>직군</th>
                  <td>
                    <div>
                      <span>프론트엔드</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-6 channel">
          <div class="wrapper">
            <div class="profile">
              <div class="pic">
                <span class="img" alt="프로필 사진" style="background-image: url(/assets/img/img.africalib.png)"></span>
              </div>
              <div class="desc">
                <span class="mr-2">그리고 부캐도 있어요</span>
                <i class="fa fa-youtube-play color-danger" aria-hidden="true"></i>
              </div>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <th>이름</th>
                  <td>
                    <div>아프리카도서관</div>
                  </td>
                </tr>
                <tr>
                  <th>주제</th>
                  <td>
                    <div>프로그래밍 강의</div>
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    <div>
                      <a href="https://africalib.org" target="_blank">africalib.org</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>채널</th>
                  <td>
                    <div>
                      <a href="https://youtube.com/channel/UCfm6gDYNrEW2hXCcIrVvj0w" target="_blank">youtube.com/channel/UCfm6gDYNrEW2hXCcIrVvj0w</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { onUnmounted } from "@vue/runtime-core";

export default {
  setup() {
    const state = reactive({
      active: {
        pic: false,
        message: false,
      },
    });

    let timer = setTimeout(() => {
      state.active.pic = true;
      clearTimeout(timer);

      timer = setTimeout(() => {
        state.active.message = true;
        clearTimeout(timer);
      }, 750);
    }, 250);

    onUnmounted(() => {
      clearTimeout(timer);
    });

    return { state };
  },
};
</script>
<style lang="scss" scoped>
.component-intro {
  background: #f7f7f7;
  padding: 25px 0;

  .container {
    > .subject {
      font-size: 18px;
      margin-bottom: 17.5px;
      font-weight: bold;
      padding: 0 5px;
    }

    .service {
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      padding: 35px;

      .logo {
        text-align: center;
        padding: 50px 15px;

        span {
          vertical-align: middle;

          &.img {
            width: 66px;
            height: 66px;
            background-size: 100% 100%;
            margin-right: 15px;
          }

          &.txt {
            font-size: 33px;
          }
        }
      }

      .text {
        .header {
          font-size: 24px;
          margin-bottom: 15px;
        }

        .body {
          line-height: 1.8;
        }
      }
    }

    .teacher {
      > div {
        > .wrapper {
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.125);
          padding: 66px 15px 50px 15px;
          overflow: hidden;

          .profile {
            text-align: center;

            .pic {
              display: inline-block;
              position: relative;
              left: 0;
              transition: left 0.5s;

              .img {
                width: 166px;
                height: 166px;
                background-size: 100% 100%;
              }

              &.active {
                left: -35px;
              }

              &.logo {
                padding-top: 35px;

                .img {
                  border-radius: 50%;
                  height: 100px;
                  width: 100px;
                }
              }
            }

            .desc {
              font-weight: bold;
              padding-top: 35px;
              font-size: 18px;
            }
          }

          table {
            max-width: 370px;
            margin: 25px auto 0 auto;
            table-layout: fixed;

            th,
            td {
              border: 0;
              padding: 7.5px 10px;
            }

            th {
              width: 100px;
              text-align: right;
            }

            td {
              div {
                display: inline-block;
                position: relative;
                width: 240px;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                img {
                  width: 20px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 0;
                  margin-right: -25px;
                }
              }
            }
          }
        }

        &.me {
          .wrapper .profile .pic .message {
            width: 100px;
            margin-right: -80px;
            margin-top: -20px;

            > span {
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      > .subject {
      }

      .service {
        .logo {
          padding: 15px 15px 35px 15px;

          span.img {
            width: 50px;
            height: 50px;
          }
        }
      }

      .teacher > div {
        .wrapper {
          padding: 50px 15px 25px 15px;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
